import React from 'react'
import posed from 'react-pose'
import animationPropTypes from '../animationPropTypes'

const Animation = posed.div({
    enter: { x: 0, opacity: 1 },
    exit: { x: -100, opacity: 0 },
})

const InFromLeft = ({ children }) => <Animation>{children}</Animation>

InFromLeft.propTypes = animationPropTypes

export default InFromLeft
