import React from 'react'
import { Modal } from 'semantic-ui-react'
import './CustomModal.scss'

const CustomModal = ({ openText, title, content }) => (
    <Modal trigger={<span className="link">{openText}</span>}>
        <Modal.Content>
            <h1>{title}</h1>
            <div>{content}</div>
        </Modal.Content>
    </Modal>
)

export default CustomModal
