import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import style from './Diamond.module.scss'

const Diamond = ({ children, isLink, name, className }) => {
    let cName = `${style.diamondShape}${name ? ` ${style[name]}` : ''}`

    if (isLink) {
        cName = `${style.diamondShape} ${style.link} ${style[name]}`
    }

    return (
        <div className={classnames(cName, className)}>
            <div className={style.inner}>{children}</div>
        </div>
    )
}

Diamond.propTypes = {
    name: PropTypes.string,
    isLink: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
}

Diamond.defaultProps = {
    name: '',
    isLink: false,
    children: React.createElement('div'),
    className: null,
}

export default Diamond
