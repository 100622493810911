import Auth from '@aws-amplify/auth'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import logException from 'tools/logger'
import { LoginForm } from 'components/forms'

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email()
        .required('Please enter your username'),
    password: Yup.string().required('Password is required!'),
})

const Login = withFormik({
    validationSchema,
    mapPropsToValues: () => ({ email: '', password: '' }),
    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
        const { email, password } = values
        const { history } = props

        Auth.signIn(email, password)
            .then((user) => {
                setSubmitting(false)
                const { userHasAuthenticated, location } = props
                const { from } = location.state || { from: { pathname: '/' } }
                userHasAuthenticated(true, user)
                history.push(from)
            })
            .catch((err) => {
                console.log('err', err)
                if (err.code === 'UserNotConfirmedException') {
                    // console.log('UserNotConfirmedException', err)
                    // console.log('redirect to confirmation code part of login form')

                    Auth.resendSignUp(email)
                        .then(() => {
                            // console.log('a verification code is sent')
                            props.setUserNotConfirmed({ email, password })
                            setSubmitting(false)
                        })
                        .catch((e) => {
                            logException(err.code)
                            history.push('/error')
                        })
                } else if (
                    err.code === 'NotAuthorizedException'
                    || err.code === 'UserNotFoundException'
                ) {
                    // Handle not found or password wrong
                    setErrors({ email: err.message })
                } else {
                    logException(err.code)
                    history.push('/error')
                }
                setSubmitting(false)
            })
    },
    displayName: 'LoginForm', // helps with React DevTools
})(LoginForm)

export default Login
