import React, { Component } from 'react'
import { ForgotPasswordForm, ForgotPasswordSubmitForm } from 'containers/forms'
import SEO from 'components/common/seo'
import FormWrapper from 'components/forms/components/form-wrapper'
import styles from './ForgotPassword.module.scss'

class Signup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            username: null,
        }
    }

    setUsername = (username) => {
        this.setState({
            username,
        })
    }

    render() {
        const { username } = this.state
        console.log('username in page component: ', username)
        return (
            <div>
                <SEO title="Forgot password" />
                <FormWrapper title="Forgot password" className={styles.forgotPassword}>
                    {!username ? (
                        <React.Fragment>
                            <p>
                                Give us your email and we will send you a confirmation code so you
                                can change your password.
                            </p>
                            <ForgotPasswordForm setUsername={this.setUsername} {...this.props} />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <p>Please add the code and new password</p>
                            <ForgotPasswordSubmitForm username={username} {...this.props} />
                        </React.Fragment>
                    )}
                </FormWrapper>
            </div>
        )
    }
}

export default Signup
