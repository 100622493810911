import { Component } from 'react'
// import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

// class ScrollToTop extends Component {
//     componentDidUpdate(prevProps) {
//         // console.log('ok', prevProps)
//         console.log('this.location = ', prevProps)
//         console.log('location', prevProps.location)
//         console.log('window', window)
//         if (this.location !== prevProps.location) {
//             window.scrollTo(0, 0)
//         }
//     }

//     render() {
//         const { children } = this.props
//         return children
//     }
// }
/*eslint-disable */
class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        console.log('scroll', prevProps)
        console.log('window', window.scrollTo(0, 0))
        window.scrollTo(0, 0)
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        return this.props.children
    }
}

export default withRouter(ScrollToTop)

// class ScrollToTop extends Component {
//     componentDidUpdate(prevProps) {
//         console.log('this.location = ', prevProps)
//         console.log('location', prevProps.location)
//         if (this.props.location !== prevProps.location) {
//             /* eslint-enable */
//             window.scrollTo(0, 0)
//         }
//     }

//     render() {
//         return this.props.children // eslint-disable-line
//     }
// }

// ScrollToTop.propTypes = {
//     children: PropTypes.node.isRequired,
//     location: PropTypes.string.isRequired,
//     // location: PropTypes.shape({
//     //     pathname: PropTypes.string,
//     //     search: PropTypes.string,
//     //     hash: PropTypes.string,
//     //     state: PropTypes.string,
//     //     key: PropTypes.string,
//     // }),
// }

// ScrollToTop.defaultProps = {
//     // location: PropTypes.string,
//     // location: PropTypes.shape({
//     //     pathname: '/',
//     //     search: '',
//     //     hash: '',
//     //     state: '',
//     //     key: '',
//     // }),
// }

// export default withRouter(ScrollToTop)
