import React from 'react'
import Loadable from 'react-loadable'

const Page = Loadable({
    loader: () => import('./Page' /* webpackChunkName: 'Page' */),
    loading() {
        return <div>Loading...</div>
    },
})

export default Page
// export { default } from './Page'
