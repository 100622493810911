import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../../fontAwesome/FontAwesome'
import Diamond from '../../common/diamondShape/Diamond'
import style from './SocialIcon.module.scss'

const SocialIcon = ({
    name, title, href, className,
}) => {
    const cName = `${style.socialIcon}${className ? ` ${style[className]}` : ''}`

    return (
        <Diamond isLink name={className}>
            <div className={cName}>
                <a href={href} title={title} target="_blank" rel="noopener noreferrer">
                    <Icon brand name={name} />
                </a>
            </div>
        </Diamond>
    )
}

SocialIcon.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    name: PropTypes.string,
    href: PropTypes.string,
}

SocialIcon.defaultProps = {
    className: 'twitter',
    title: 'twitter',
    name: 'twitter',
    href: 'href',
}

export default SocialIcon
