import React from 'react'
import posed from 'react-pose'
import animationPropTypes from '../animationPropTypes'

const Animation = posed.div({
    enter: { y: 0, opacity: 1 },
    exit: { y: 100, opacity: 0 },
})

const Up = ({ children }) => <Animation>{children}</Animation>

Up.propTypes = animationPropTypes

export default Up
