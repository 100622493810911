import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../../common/pageHeading'
import style from '../../news.module.scss'

const newsDetail = (props) => {
    const {
        title, slug, description, previewImage,
    } = props

    let bgImage = {}
    if (previewImage) {
        bgImage = {
            backgroundImage: `url(${previewImage})`,
        }
    }

    return (
        <div className={style.newsDetail}>
            <PageHeader>Get your pack now!</PageHeader>
            <div className={style.date}>Aug 18th 2018</div>
            <div className={style.featureImg}>
                <img
                    src="https://webimg.ccpgamescdn.com/7lhcm73ukv5p/6z1QyKHX9eusGqeC8Oyce2/9fe9be3103c79dc237fa2ac2e8ebc5b6/Screenshot_2560x1440.png"
                    alt=""
                />
            </div>
            <div className={style.content}>
                <div>
                    <p>
                        During the
                        <a href="https://forums.eveonline.com/t/dev-blog-new-recruitment-program-kicks-off-on-july-11/87784">
                            introduction of the new Recruitment Program on July 11th
                        </a>
                        , we made mention that any outstanding rewards from the old system would be
                        delivered to those eligible later in the year.
                    </p>
                    <p>
                        The old Recruitment Program had a “pre-redeeming” queue where pilots who had
                        earned rewards would have to go to account management to claim them and
                        deliver them to their redeeming queue.
                    </p>
                    <p>
                        Since we launched the new system, pilots who had not claimed their rewards
                        in the old system have not been able to access them.
                    </p>
                    <p>
                        Today, we’ve distributed these rewards, which have been credited to their
                        accounts where appropriate.
                    </p>
                    <ul>
                        <li>
                            Those who were eligible for Omega rewards have been issued with Omega
                            time.
                        </li>
                        <li>
                            Those who were eligible for Omega/PLEX rewards have been issued with
                            PLEX to the total value of the reward.
                        </li>
                    </ul>
                    <p>
                        Please be aware that rewards have only been issued for accounts that have
                        been active after 2017/07/01. Pilots with accounts that have not been active
                        since then will need to contact customer support to have their rewards
                        issued.
                    </p>
                </div>
            </div>
        </div>
    )
}

newsDetail.propTypes = {
    title: PropTypes.string,
    slug: PropTypes.string,
    description: PropTypes.string,
    previewImage: PropTypes.string,
}

newsDetail.defaultProps = {
    title: 'Dawn of Liberation',
    slug: '/dawn-of-liberation',
    description: 'Quisque pellentesque sem nec libero tempus, sed condimentum ante',
    previewImage:
        '//webimg.ccpgamescdn.com/7lhcm73ukv5p/3WFKI2ZGRa4UAO0coW6e2c/0e691f702ade734b1625d948052a0a71/fanartFriday.PNG_w=640',
}

export default newsDetail
