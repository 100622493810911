import React from 'react'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'

const Checkout = Loadable({
    loader: () => import('./Checkout' /* webpackChunkName: 'Checkout' */),
    loading() {
        return <Loading />
    },
})

export default Checkout
