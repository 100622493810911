export const environment = process.env.REACT_APP_ENV
export const nodeenvironment = process.env.NODE_ENV
export const host = process.env.HOST
export const isDevelopment = environment === 'development'
export const isStaging = environment === 'staging'
export const isProduction = environment === 'production'

const development = {
    env: 'development',
    cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: 'eu-west-1_5aXUqr3hS', // Nova User Pool
        APP_CLIENT_ID: '2qftvb8c09nq1ucesuhfvffvbs', // Nova User Pool App Id
        // USER_POOL_ID: 'eu-west-1_BtQFxS49v', // Web-Development User Pool
        // APP_CLIENT_ID: 'g7ktr0dufc7r1tmsksbmk7355', // Web-Development User Pool App Id
        IDENTITY_POOL_ID: 'YOUR_IDENTITY_POOL_ID',
    },
    sentry: {
        dsn: 'https://3993da88f1ec4aff8714e7abaac6bb07@sentry.evetech.net/69',
    },
    store: {
        CHECKOUT: 'https://fortuna-staging.batcave.ccp.cc/cashier/api/v1/checkout/',
        CATALOG: 'https://fortuna-staging.batcave.ccp.cc/front/product/list/nova/',
    },
}

const production = {
    env: 'production',
    cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: 'eu-west-1_5aXUqr3hS',
        APP_CLIENT_ID: '6l66l47dkkpn6ducnt5mmml1eg',
        IDENTITY_POOL_ID: 'YOUR_IDENTITY_POOL_ID',
    },
    sentry: {
        dsn: 'https://3993da88f1ec4aff8714e7abaac6bb07@sentry.evetech.net/69',
    },
    store: {
        CHECKOUT: 'https://fortuna-staging.batcave.ccp.cc/cashier/api/v1/checkout/',
        CATALOG: 'https://fortuna-staging.batcave.ccp.cc/front/product/list/nova/',
    },
}

// console.log('environment', environment)
// console.log('development config', development)
// console.log('production config', production)

export default (isDevelopment ? development : production)
