import React from 'react'
import PropTypes from 'prop-types'
import logException from 'tools/logger'
import ErrorPage from './ErrorPage'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true })
        // You can also log the error to an error reporting service
        logException(error, info)
    }

    render() {
        const { hasError } = this.state

        if (hasError) {
            return <ErrorPage />
        }

        const { children } = this.props
        return children
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
}

export default ErrorBoundary
