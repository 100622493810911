import React from 'react'
import { Link } from 'react-router-dom'
// import PropTypes from 'prop-types'
import style from './footer.module.scss'
import Container from '../layout/container'
import SocialIcons from '../social-icons'
import pegiImg from '../../static/pegi.png'

const Footer = () => (
    <footer>
        <Container>
            <div className={style.footerContainer}>
                <div className={style.follow}>
                    <h3>Follow EVE Vanguard</h3>
                </div>
                <div className={style.social}>
                    <SocialIcons />
                </div>
                <div className={style.vanguard}>
                    <ul>
                        <li>EVE: Vanguard</li>
                        <li>
                            <Link to="/store">Founder's Packs</Link>
                        </li>
                        <li>
                            <Link to="/download">Download the game</Link>
                        </li>
                        <li>
                            <Link to="/download"> System requirements</Link>
                        </li>
                        <li>
                            <Link to="/news">In the news</Link>
                        </li>
                    </ul>
                </div>
                <div className={style.universe}>
                    <ul>
                        <li>EVE: Vanguard</li>
                        <li>
                            <a href="/" target="_blank" rel="noopener noreferrer">
                                Founders pack - Premium
                            </a>
                        </li>
                        <li>
                            <a href="/" target="_blank" rel="noopener noreferrer">
                                Founders pack - Basic
                            </a>
                        </li>
                        <li>
                            <a href="/download" target="_blank" rel="noopener noreferrer">
                                Download the game
                            </a>
                        </li>
                        <li>
                            <a href="/" target="_blank" rel="noopener noreferrer">
                                System requirements
                            </a>
                        </li>
                    </ul>
                </div>
                <div className={style.info}>
                    <ul>
                        <li>EVE: Vanguard</li>
                        <li>
                            <a href="/" target="_blank" rel="noopener noreferrer">
                                Founders pack - Premium
                            </a>
                        </li>
                        <li>
                            <a href="/" target="_blank" rel="noopener noreferrer">
                                Founders pack - Basic
                            </a>
                        </li>
                        <li>
                            <a href="/download" target="_blank" rel="noopener noreferrer">
                                Download the game
                            </a>
                        </li>
                        <li>
                            <a href="/" target="_blank" rel="noopener noreferrer">
                                System requirements
                            </a>
                        </li>
                    </ul>
                </div>
                <div className={style.copyright}>
                    Copyright © CCP 1997-
                    {new Date().getFullYear()}
                </div>
                <div className={style.pegi}>
                    <a href="http://www.pegi.info/" title="Visit the PEGI webpage">
                        <img alt="" src={pegiImg} />
                    </a>
                </div>
            </div>
        </Container>
    </footer>
)

export default Footer
