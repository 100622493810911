import React, { Component } from 'react'
import Auth from '@aws-amplify/auth'
import { withRouter } from 'react-router-dom'
import ErrorBoundary from 'containers/error/ErrorBoundary'
import Header from 'components/header/Header'
import Footer from 'components/footer/Footer'
import Routes from './routes'
import 'normalize.css'

import style from './App.module.scss'
import './components/fontAwesome/Lib'

export const AppContext = React.createContext()

const handleLogout = async (event) => {
    await Auth.signOut()
    this.userHasAuthenticated(false, null)
    const { history } = this.props
    history.push('/')
}

export { handleLogout }

class App extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isAuthenticated: false,
            isAuthenticating: true,
            user: null,
        }
    }

    async componentDidMount() {
        try {
            const user = await Auth.currentSession()
            if (user) {
                console.log('user', user)
                this.userHasAuthenticated(true, user)
                this.state.user = user
            }
        } catch (e) {
            if (e !== 'No current user') {
                // alert(e)
            }
            // else {
            //     console.log('Exception ', e)
            // }
        }

        this.setState({ isAuthenticating: false })

        this.LoadingDelay().then(() => {
            const ele = document.getElementById('ipl-progress-indicator')
            if (ele) {
                // fade out
                ele.classList.add('available')
                setTimeout(() => {
                    // remove from DOM
                    ele.outerHTML = ''
                }, 2000)
            }
        })
    }

    userHasAuthenticated = (authenticated, user) => {
        this.setState({
            isAuthenticated: authenticated,
            user: user ? user.signInUserSession : null,
        })
    }

    handleLogout = async (event) => {
        await Auth.signOut()
        this.userHasAuthenticated(false, null)
        const { history } = this.props
        history.push('/')
    }

    // fake authentication Promise
    LoadingDelay = () => new Promise(resolve => setTimeout(resolve, 800))

    render() {
        const { isAuthenticated, isAuthenticating } = this.state
        const { location } = this.props

        const childProps = {
            isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated,
            handleLogout: this.handleLogout,
        }

        return (
            <ErrorBoundary>
                <AppContext.Provider value={this.state}>
                    <Header logout={this.handleLogout} />
                    {!isAuthenticating && <Routes location={location} childProps={childProps} />}
                    <Footer />
                </AppContext.Provider>
            </ErrorBoundary>
        )
    }
}

export default withRouter(App)
