import React from 'react'
import posed from 'react-pose'
import animationPropTypes from '../animationPropTypes'

const Animation = posed.div({
    enter: {
        opacity: 1,
        delay: 250,
        beforeChildren: true,
    },
    exit: { opacity: 0, transition: { type: 'spring', stiffness: 100 } },
})

const In = ({ children }) => <Animation>{children}</Animation>

In.propTypes = animationPropTypes

export default In
