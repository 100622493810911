import React from 'react'
import PropTypes from 'prop-types'
import style from './pageHeading.module.scss'

const pageHeading = ({ children }) => <h2 className={style.title}>{children}</h2>

pageHeading.propTypes = {
    children: PropTypes.node.isRequired,
}

export default pageHeading
