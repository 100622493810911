import React from 'react'
import PropTypes from 'prop-types'
import style from './SquareButton.module.scss'

const SquareButton = ({ children, disabled }) => (
    <button type="submit" className={style.squareButton} disabled={disabled}>
        {children}
    </button>
)

SquareButton.propTypes = {
    // children: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
}

SquareButton.defaultProps = {
    disabled: false,
}

export default SquareButton
