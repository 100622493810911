import React from 'react'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'

const News = Loadable({
    loader: () => import('./News' /* webpackChunkName: 'News' */),
    loading() {
        return <Loading />
    },
})

export default News
// export { default } from './News'
