import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import s from './container.module.scss'

const Container = (props) => {
    const { children, className } = props

    // const classes = [s.container]

    return <div className={classnames(s.container, className)}>{children}</div>
}

Container.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    // className: PropTypes.string,
}

Container.defaultProps = {
    children: React.createElement('div'),
    className: null,
}

export default Container
