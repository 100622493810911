import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Icon from '@fortawesome/react-fontawesome'

function FontAwesome(props) {
    const {
        name, brand, regular, solid, light,
    } = props
    let prefix = 'l'
    if (brand) prefix = 'b'
    if (regular) prefix = 'r'
    if (solid) prefix = 's'
    if (light) prefix = 'l'
    const icon = [`fa${prefix}`, name]

    return (
        <Fragment>
            <Icon icon={icon} />
        </Fragment>
    )
}

export default FontAwesome

FontAwesome.propTypes = {
    name: PropTypes.string,
    brand: PropTypes.bool,
    regular: PropTypes.bool,
    solid: PropTypes.bool,
    light: PropTypes.bool,
}
FontAwesome.defaultProps = {
    name: 'twitter',
    brand: true,
    regular: false,
    solid: false,
    light: false,
}
