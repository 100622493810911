import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
// import classNames from 'classnames'
// import { Trapezoid } from '../../components/button'
// import PageLayout from '../../components/layout/pageLayout/PageLayout'
import Container from '../../components/layout/container'
import Icon from '../../components/fontAwesome/FontAwesome'
// import {
//     Button, Header, Image, Modal, Card, Icon, Input, Form, TextArea,
// } from 'semantic-ui-react'
import Diamond from '../../components/common/diamondShape/Diamond'
import SingleProduct from '../../components/store/products/product'
import style from './packs.module.scss'

const Packs = () => {
    // const classinn = classnames(classNames(style('product'))
    console.log('klassinn', 'classinn')
    return (
        <Fragment>
            <Helmet>
                <title>Page</title>
                <meta property="og:url" content="https://ccpweb.netlify.com/page" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Page" />
                <meta property="og:description" content="This is a sub page" />
                <meta property="og:image" content="https://picsum.photos/300/200" />
            </Helmet>
            <div className={style.pageContent}>
                <Container>
                    <h1>Get your packs on!</h1>
                </Container>
                <div className={style.prodWrap}>
                    <SingleProduct
                        title="Elysians pack - premium"
                        slug="elysian-pack"
                        price="49.99"
                        buttonUrl="/store"
                        description="Be there from the beginning and get a 2-day head start in EVE Vanguard! The Elysians Pack includes 3 exclusive Dropsuits featuring unique combos of weapons, attachments and attributes that will never be made available again. "
                    />
                    <SingleProduct
                        title="Revenant pack - basic"
                        slug="elysian-pack"
                        price="39.99"
                        buttonUrl="/checkout/revenant-pack"
                        description="Be there from the beginning and get a 2-day head start in EVE Vanguard! The Elysians Pack includes 3 exclusive Dropsuits featuring unique combos of weapons, attachments and attributes that will never be made available again. "
                    />
                </div>
                <Container>
                    <h4>Advantages of ELYSIANS pack</h4>
                    <div className={style.advantages}>
                        <div className={style.item}>
                            <Diamond name="redBig">
                                <Icon brand name="twitter" />
                            </Diamond>
                            <h5>Early access</h5>
                            <p>
                                Unlimited access to skills and double training speed Take advantage
                                of an Infinite skill queue
                            </p>
                        </div>
                        <div className={style.item}>
                            <Diamond name="redBig">
                                <Icon light name="download" />
                            </Diamond>
                            <h5>Forum badge</h5>
                            <p>
                                Ulimited access to skills and double training speed Take advantage
                                of an Infinite skill queue
                            </p>
                        </div>
                        <div className={style.item}>
                            <Diamond name="redBig">
                                <Icon solid name="square" />
                            </Diamond>
                            <h5>Early access</h5>
                            <p>
                                Unlimited access to skills and double training speed Take advantage
                                of an Infinite skill queue
                            </p>
                        </div>
                    </div>
                </Container>
            </div>
        </Fragment>
    )
}

export default Packs
