import React from 'react'
import PropTypes from 'prop-types'
// import Routes from './routes'
import classNames from 'classnames'
import style from './PageLayout.module.scss'

const PageLayout = ({ children, className }) => {
    const wrapperClass = classNames(style.pageWrapper, style[className])

    return (
        <div className={wrapperClass}>
            <div className={className === 'default' ? style.page : ''}>{children}</div>
        </div>
    )
}

PageLayout.propTypes = {
    children: PropTypes.node,    
    className: PropTypes.string,
}

PageLayout.defaultProps = {
    children: React.createElement('div'),    
    className: 'default',    
}

export default PageLayout
