import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'semantic-ui-react'
import ReCAPTCHA from 'react-recaptcha'
import CustomModal from '../../modals'
import { SquareButton } from '../../button'
import termsOfService from './TermsOfService.json'
import '../Form.scss'

class Signup extends Component {
    componentDidMount() {
        const script = document.createElement('script')
        script.src = 'https://www.google.com/recaptcha/api.js'
        script.async = true
        script.defer = true
        document.body.appendChild(script)
    }

    render() {
        const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
        } = this.props

        return (
            <form onSubmit={handleSubmit} className="login ui form">
                <div className="field">
                    <label htmlFor="email" style={{ display: 'block' }}>
                        Email
                        <div className="ui transparent input">
                            <input
                                id="email"
                                type="text"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={
                                    errors.email && touched.email
                                        ? 'text-input error'
                                        : 'text-input'
                                }
                            />
                        </div>
                    </label>
                    {errors.email && touched.email ? (
                        <div className="input-feedback">{errors.email}</div>
                    ) : (
                        <div className="input-feedback-empty" />
                    )}
                </div>

                <div className="field">
                    <label htmlFor="password" style={{ display: 'block' }}>
                        Password
                        <div className="ui transparent input">
                            <input
                                id="password"
                                type="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={
                                    errors.password && touched.password
                                        ? 'text-input error'
                                        : 'text-input'
                                }
                            />
                        </div>
                    </label>

                    {errors.password && touched.password ? (
                        <div className="input-feedback">{errors.password}</div>
                    ) : (
                        <div className="input-feedback-empty" />
                    )}
                </div>

                <div className="field">
                    <Checkbox
                        id="terms"
                        label={<label htmlFor="terms">I agree to the </label>}
                        onChange={handleChange}
                    />
                    <CustomModal
                        openText=" Terms of Service"
                        title={termsOfService.title}
                        content={termsOfService.content}
                    />
                    {errors.terms && touched.terms ? (
                        <div className="input-feedback">{errors.terms}</div>
                    ) : (
                        <div className="input-feedback-empty" />
                    )}
                </div>
                <div className="field">
                    <ReCAPTCHA
                        sitekey="6LePK3QUAAAAAMSrFq8GvCuYHpNdPcZbTnHYtk7q"
                        render="explicit"
                        theme="dark"
                        verifyCallback={(response) => {
                            setFieldValue('recaptcha', response)
                        }}
                        onloadCallback={() => {
                            console.log('Recaptha done loading!')
                        }}
                    />
                    {errors.recaptcha && touched.recaptcha ? (
                        <div className="input-feedback">{errors.recaptcha}</div>
                    ) : (
                        <div className="input-feedback-empty" />
                    )}
                </div>
                <div className="field">
                    <SquareButton disabled={isSubmitting}>Start Playing Now</SquareButton>
                </div>
            </form>
        )
    }
}

Signup.propTypes = {
    values: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,
    isSubmitting: PropTypes.bool,
}
Signup.defaultProps = {
    values: null,
    errors: null,
    touched: false,
    handleChange: null,
    handleBlur: null,
    handleSubmit: null,
    setFieldValue: null,
    isSubmitting: false,
}

export default Signup
