import React from 'react'
import PropTypes from 'prop-types'
import style from './Button.module.scss'

const LogoutButton = ({ children, handleClick, ...rest }) => (
    <a onClick={handleClick} className={style.loginButton} {...rest}>
        {children}
    </a>
)

LogoutButton.propTypes = {
    children: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
}

export default LogoutButton
