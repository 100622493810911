import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import Visibility from '../../../common/visibility'
import Icon from '../../../fontAwesome/FontAwesome'
import Trapezoid from '../../../button/components/trapezoid'
import style from './singleProduct.module.scss'

const singleProduct = (props) => {
    const {
        title, type, slug, description, img1, img2, price,
    } = props

    console.log(title, slug, description, img1, img2, price)
    const buttonText = `Pre-order - ${price}`

    const buttonUrl = `/checkout/${slug}`

    // let bgImage = {}
    // if (img1) {
    //     bgImage = {
    //         backgroundImage: `url(${img1})`,
    //     }
    // }

    // let bgImage2 = {}
    // if (img2) {
    //     bgImage2 = {
    //         backgroundImage: `url(${img1})`,
    //     }
    // }

    return (
        <div className={classnames(style.product, style[type])}>
            <div className={style.deco}>
                <Visibility delay={type === 'basic'}>
                    <div className={style.character} />
                </Visibility>
                <div className={style.buy}>
                    <Trapezoid type={type} to={buttonUrl}>{buttonText}</Trapezoid>
                </div>
                <span className={style.caption}>{title}</span>
            </div>
            <div className={style.detail}>
                <h3>{title} - {type}</h3>
                <p>{description}</p>
                <Link className={style.link} to={buttonUrl}>
                    Learn more <Icon light name="angle-right" />
                </Link>
            </div>
        </div>
    )
}

singleProduct.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    slug: PropTypes.string,
    description: PropTypes.string,
    img1: PropTypes.string,
    img2: PropTypes.string,
    // buttonUrl: PropTypes.string,
    price: PropTypes.string,
}

singleProduct.defaultProps = {
    title: 'Elysian pack',
    type: '',
    slug: 'founders-pack',
    description:
        'Be there from the beginning and get a 2-day head start in EVE Vanguard! The Elysians Pack includes 3 exclusive Dropsuits featuring unique combos of weapons, attachments and attributes that will never be made available again',
    img1: 'img url',
    img2: 'img url',
    // buttonUrl: 'button url',
    price: '39.99',
}

export default singleProduct
