import Auth from '@aws-amplify/auth'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import logException from 'tools/logger'
import { ForgotPasswordForm } from 'components/forms'

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email()
        .required('Please enter your email'),
})

const ForgotPassword = withFormik({
    validationSchema,
    mapPropsToValues: () => ({ email: '' }),
    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
        console.log('values', values)
        console.log('props', props)

        const { email } = values
        const { history } = props

        console.log('email', email)
        // setSubmitting(false)
        // setErrors({ email: 'error_needs_implementation' })

        Auth.forgotPassword(email)
            .then((data) => {
                console.log('called forgotPassword data: ', data)

                // This sets new user for next step.
                // Triggers state update in parent that renders confirmation code form.
                props.setUsername(email)
                setSubmitting(false)
            })
            .catch((err) => {
                if (err.code === 'UserNotFoundException') {
                    // logException(err.code)
                    setErrors({ email: 'User does not exis.' })
                } else {
                    logException(err.code)
                    history.push('/error')
                }
                // console.log('error when calling forgotPassword.', err)
                setSubmitting(false)
            })
    },
    displayName: 'ForgotPasswordForm', // helps with React DevTools
})(ForgotPasswordForm)

export default ForgotPassword
