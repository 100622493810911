import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import style from './Button.module.scss'

const LoginButton = ({ children, to }) => (
    <Link to={to} className={style.loginButton}>
        {children}
    </Link>
)

LoginButton.propTypes = {
    children: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
}

export default LoginButton
