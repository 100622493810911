import React from 'react'
import { Route } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import PageLayout from '../components/layout/pageLayout/PageLayout'

export default ({ component: Component, props: cProps, ...rest }) => (
    <PageLayout className={rest.template} handleLogout={cProps.handleLogout}>
        {!rest.private ? (
            <Route {...rest} render={props => <Component {...props} {...cProps} />} />
        ) : (
            <PrivateRoute component={Component} {...rest} />
        )}
    </PageLayout>
)
