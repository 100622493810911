import Auth from '@aws-amplify/auth'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { ConfirmationForm } from 'components/forms'

const validationSchema = Yup.object().shape({
    confirmationCode: Yup.string()
        .matches(/(^[0-9]+$)/, 'Confirmation code should only contain digits')
        .min(6, 'Confirmation codes should be 6 digits')
        .max(6, 'Confirmation codes should be 6 digits')
        .required('Please enter your confirmation code'),
})

const Confirmation = withFormik({
    validationSchema,
    mapPropsToValues: () => ({ confirmationCode: '' }),
    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
        console.log('values', values)
        console.log('props', props)
        console.log('setSubmitting', setSubmitting)

        const { confirmationCode } = values
        console.log('the confirmationCode is: ', confirmationCode)

        const { newUser } = props

        console.log('newUser', newUser)

        Auth.confirmSignUp(newUser.email, confirmationCode)
            .then(() => {
                console.log('user confirmed, logging in now.')

                Auth.signIn(newUser.email, newUser.password)
                    .then((user) => {
                        console.log('user', user)
                        // Finally logging user in.
                        const { userHasAuthenticated, location, history } = props
                        const { from } = location.state || { from: { pathname: '/' } }
                        userHasAuthenticated(true, user)
                        setSubmitting(false)
                        history.push(from)
                    })
                    .catch((err) => {
                        console.log('error when logging in through signup form. ', err)
                        setErrors({ confirmationCode: err.message })
                        setSubmitting(false)
                    })
            })
            .catch((err) => {
                console.log('error when setting confirmationCode. ', err)
                setErrors({ confirmationCode: err.message })
                setSubmitting(false)
            })
    },
    displayName: 'ConfirmationForm', // helps with React DevTools
})(ConfirmationForm)

export default Confirmation
