import React from 'react'
import Loadable from 'react-loadable'
import Loading from 'components/loading'
import { Stagger } from 'components/animations'

const Login = Loadable({
    loader: () => import('./Login' /* webpackChunkName: 'Login' */),
    loading() {
        return <Loading />
    },
})

export default props => (
    <Stagger>
        <Login {...props} />
    </Stagger>
)
