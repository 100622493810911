import React from 'react'
import Loadable from 'react-loadable'
import Loading from '../../components/loading'

const Download = Loadable({
    loader: () => import('./Download' /* webpackChunkName: 'Download' */),
    loading() {
        return <Loading />
    },
})

export default Download
