import React from 'react'
import Loadable from 'react-loadable'
import Loading from 'components/loading'
import { Stagger } from 'components/animations'

const Signup = Loadable({
    loader: () => import('./Signup' /* webpackChunkName: 'Signup' */),
    loading() {
        return <Loading />
    },
})

export default props => (
    <Stagger>
        <Signup {...props} />
    </Stagger>
)
