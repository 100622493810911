import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AppContext } from '../App'

const PrivateRoute = ({ component: Component, ...rest }) => (
    <AppContext.Consumer>
        {context => (
            <Route
                {...rest}
                render={props => (context.user ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/signup',
                            state: { from: props.location },
                        }}
                    />
                ))
                }
            />
        )}
    </AppContext.Consumer>
)

export default PrivateRoute
