import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'react-router-dom'
import posed, { PoseGroup } from 'react-pose'

import AppliedRoute from './AppliedRoute'
import Home from '../containers/home'
import Page from '../containers/page'
import News from '../containers/news'
import NewsDetail from '../containers/news/NewsDetailPage'
import Login from '../containers/login'
import Signup from '../containers/signup'
import ForgotPassword from '../containers/forgotpassword'
import Download from '../containers/download'
import Store from '../containers/store'
import Checkout from '../containers/checkout'
import Packs from '../containers/packs'
import Error from '../containers/error'
import NotFound from '../containers/notfound'

const RoutesContainer = posed.div({
    // enter: {
    //     opacity: 1,
    //     delay: 250,
    //     beforeChildren: true,
    // },
    // exit: { opacity: 0, transition: { type: 'spring', stiffness: 100 } },

    enter: {
        y: 0,
        opacity: 1,
        delay: 100,
        beforeChildren: true,
    },
    exit: {
        y: 50,
        opacity: 0,
        transition: { duration: 200 },
    },
})

const Routes = ({ location, childProps }) => (
    <PoseGroup>
        <RoutesContainer key={location.pathname}>
            <Switch location={location}>
                <AppliedRoute template="home" path="/" exact component={Home} props={childProps} />
                <AppliedRoute path="/news" exact component={News} props={childProps} />
                <AppliedRoute path="/news/:id" component={NewsDetail} props={childProps} />
                <AppliedRoute path="/page" component={Page} props={childProps} />
                <AppliedRoute path="/download" component={Download} props={childProps} />
                <AppliedRoute template="store" path="/store" component={Store} props={childProps} />
                <AppliedRoute
                    path="/checkout/:id"
                    component={Checkout}
                    props={childProps}
                    private
                />
                <AppliedRoute path="/packs" component={Packs} props={childProps} />
                <AppliedRoute
                    path="/forgotpassword"
                    exact
                    component={ForgotPassword}
                    props={childProps}
                />
                <AppliedRoute path="/signup" exact component={Signup} props={childProps} />
                <AppliedRoute path="/login" exact component={Login} props={childProps} />
                <AppliedRoute path="/error" component={Error} status={500} props={childProps} />
                <AppliedRoute component={NotFound} status={404} props={childProps} />
            </Switch>
        </RoutesContainer>
    </PoseGroup>
)

Routes.propTypes = {
    childProps: PropTypes.shape({
        isAuthenticated: PropTypes.bool,
        userHasAuthenticated: PropTypes.func,
    }).isRequired,
    location: PropTypes.shape({
        key: PropTypes.string,
    }).isRequired,
}

export default Routes
