import Auth from '@aws-amplify/auth'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import logException from 'tools/logger'
import { ForgotPasswordSubmitForm } from 'components/forms'

const validationSchema = Yup.object().shape({
    confirmationCode: Yup.string()
        .matches(/(^[0-9]+$)/, 'Confirmation code should only contain digits')
        .min(6, 'Confirmation codes should be 6 digits')
        .max(6, 'Confirmation codes should be 6 digits')
        .required('Please enter your confirmation code'),
    password: Yup.string()
        .min(6, 'Password has to be longer than 6 characters!')
        .required('Password is required!'),
})

const ForgotPasswordSubmit = withFormik({
    validationSchema,
    mapPropsToValues: () => ({ confirmationCode: '', password: '' }),
    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
        console.log('values', values)
        console.log('props', props)

        const { confirmationCode, password } = values
        console.log('code and password: ', confirmationCode, password)

        const { username } = props
        const { history } = props
        console.log('username', username)

        // setSubmitting(false)
        // setErrors({ confirmationCode: 'code_not_implemented' })

        Auth.forgotPasswordSubmit(username, confirmationCode, password)
            .then((data) => {
                console.log('data from forgotPasswordSubmit', data)

                Auth.signIn(username, password)
                    .then((user) => {
                        console.log('user', user)
                        // Finally logging user in.
                        const { userHasAuthenticated } = props
                        // const { from } = location.state || { from: { pathname: '/' } }
                        userHasAuthenticated(true, user)
                        setSubmitting(false)
                        history.push('/')
                    })
                    .catch((err) => {
                        logException(err.code)
                        history.push('/error')
                        // console.log('error when logging in through forgot password form. ', err)
                        // setErrors({ confirmationCode: err.message })
                        // setSubmitting(false)
                    })

                // This sets new user for next step.
                // Triggers state update in parent that renders confirmation code form.
            })
            .catch((err) => {
                console.log('error when submitting forgot password. ', err)
                if (err.code === 'InvalidPasswordException') {
                    // SHOWS: Password does not conform to policy: Password must have uppercase characters
                    setErrors({ password: err.message })
                    setSubmitting(false)
                } else {
                    logException(err.code)
                    history.push('/error')
                    // setErrors({ confirmationCode: err.message }) // TODO error handling
                }
            })
    },
    displayName: 'ForgotPasswordSubmitForm', // helps with React DevTools
})(ForgotPasswordSubmitForm)

export default ForgotPasswordSubmit
