import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import Container from '../../components/layout/container'
// import PageHeader from '../../components/common/pageHeading'
import NewsDetail from '../../components/news/components/newsDetail'
import style from './News.module.scss'

const NewsDetailPage = () => (
    <Fragment>
        <Helmet>
            <title>News</title>
            <meta property="og:url" content="https://ccpweb.netlify.com/news" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="News" />
            <meta property="og:description" content="This is the news site." />
            <meta property="og:image" content="https://picsum.photos/300/200" />
        </Helmet>
        <Container>
            <div className={style.newsDetailGrid}>
                <div className={style.mainContent}>
                    <NewsDetail />
                </div>
                <div className={style.sidebar}>
                    <p>the sidebar</p>
                </div>
            </div>
        </Container>
    </Fragment>
)

export default NewsDetailPage
