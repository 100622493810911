import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
// import paths from '../../config/paths'

const sitename = 'EVE Vanguard'
const getTitleWithPrefix = title => `${title} | ${sitename}`
const seoURL = path => `https://localhost:3000/${path}` // TODO: use paths from config?

const getMetaTags = ({
    title, description, url, image,
}) => {
    const metaTags = [
        { property: 'og:url', content: url },
        { property: 'og:site_name', content: sitename },
    ]

    if (title) {
        metaTags.push({ itemprop: 'name', content: getTitleWithPrefix(title) })
        metaTags.push({
            property: 'og:title',
            content: getTitleWithPrefix(title),
        })
    }
    if (description) {
        metaTags.push({ name: 'description', content: description })
        metaTags.push({ itemprop: 'description', content: description })
        metaTags.push({ property: 'og:description', content: description })
    }
    if (image) {
        metaTags.push({ itemprop: 'image', content: image })
        metaTags.push({ property: 'og:image', content: image })
        metaTags.push({ name: 'twitter:card', content: 'summary_large_image' })
    }

    return metaTags
}

const SEO = ({
    schema, title, description, pathname, image,
}) => (
    <Helmet
        htmlAttributes={{
            lang: 'en',
            itemscope: undefined,
            itemtype: `http://schema.org/${schema}`,
        }}
        title={title}
        titleTemplate={`${sitename} - %s`}
        link={[{ rel: 'canonical', href: seoURL(pathname) }]}
        meta={getMetaTags({
            title,
            description,
            url: seoURL(pathname),
            image,
        })}
    />
)

SEO.propTypes = {
    schema: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    pathname: PropTypes.string,
    image: PropTypes.string,
}

SEO.defaultProps = {
    schema: 'VideoGame',
    title: 'Explosive co-op shooter in the EVE universe',
    description: 'EVE Vanguard is a dark and thrilling first-person co-op shooter set in a dystopian interstellar future, featuring strong emphasis on character progression and mastery, co-evolved with the community.',
    pathname: 'https://localhost:3000',
    image: null,
}

export default SEO
