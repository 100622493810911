import Auth from '@aws-amplify/auth'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import logException from 'tools/logger'
import { SignupForm } from 'components/forms'

const validationSchema = Yup.object().shape({
    recaptcha: Yup.string().required('Please verify you are not a robot'),
    email: Yup.string()
        .email()
        .required('Please enter your username'),
    password: Yup.string()
        .min(6, 'Password has to be longer than 6 characters')
        .required('Password is required!'),
    terms: Yup.boolean().oneOf([true], 'You have to agree to the terms of service'),
})

const Signup = withFormik({
    validationSchema,
    // setFieldValue: response => ({ recaptcha: { response } }),
    mapPropsToValues: () => ({
        email: '',
        password: '',
        terms: false,
        recaptcha: '',
    }),
    handleSubmit: (values, { props, setSubmitting, setErrors }) => {
        // console.log('values', values)
        // console.log('props', props)

        const { email, password } = values
        const { history } = props

        Auth.signUp({
            username: email,
            password,
            attributes: {
                email,
            },
        })
            .then((user) => {
                console.log('newly created user', user)

                // This sets new user for next step.
                // Triggers state update in parent that renders confirmation code form.
                props.setNewUser({ email, password })
                setSubmitting(false)
            })
            .catch((err) => {
                console.log('error when signing up. ', err)
                if (err.code === 'InvalidPasswordException') {
                    // logException(err.code)
                    setErrors({ password: err.message })
                } else if (err.code === 'UsernameExistsException') {
                    // logException(err.code)
                    setErrors({ email: err.message })
                } else {
                    logException(err.code)
                    history.push('/error')
                }
                setSubmitting(false)
            })
    },
    displayName: 'SignupForm', // helps with React DevTools
})(SignupForm)

export default Signup
