import React from 'react'
import PropTypes from 'prop-types'
import '../Form.scss'
import { SquareButton } from '../../button'

const ForgotPassword = (props) => {
    const {
        values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit,
    } = props
    return (
        <form onSubmit={handleSubmit} className="login ui form">
            <div className="field">
                <label htmlFor="email" style={{ display: 'block' }}>
                    Email
                    <div className="ui transparent input">
                        <input
                            id="email"
                            type="text"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                                errors.email && touched.email ? 'text-input error' : 'text-input'
                            }
                        />
                    </div>
                </label>
                {errors.email && touched.email ? (
                    <div className="input-feedback">{errors.email}</div>
                ) : (
                    <div className="input-feedback-empty" />
                )}
            </div>

            <div className="field">
                <SquareButton disabled={isSubmitting}>Submit</SquareButton>
            </div>
        </form>
    )
}

ForgotPassword.propTypes = {
    values: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handleSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool,
}
ForgotPassword.defaultProps = {
    values: null,
    errors: null,
    touched: false,
    handleChange: null,
    handleBlur: null,
    handleSubmit: null,
    isSubmitting: false,
}

export default ForgotPassword
